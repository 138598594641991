import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';

const Login = ({ onLoginSuccess }) => {
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000';

    const login = useGoogleLogin({
        flow: 'auth-code',
        ux_mode: 'redirect',
        redirect_uri: `${window.location.origin}/auth/callback`,
        onSuccess: async (codeResponse) => {
            console.log("Login Success:", codeResponse);
            await handleGoogleLogin(codeResponse.code);
        },
        onError: (error) => {
            console.log('Login Failed:', error);
            setError('Login failed. Please try again.');
        }
    });

    const handleGoogleLogin = async (code) => {
        try {
            const response = await fetch(`${apiBaseUrl}/api/v1/auth/google-login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ code }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('Server error:', errorData);
                setError(errorData.detail || 'Failed to get JWT from server');
                return;
            }

            const data = await response.json();
            if (data.access_token) {
                onLoginSuccess(data.access_token);
                navigate('/map');
            } else {
                setError('JWT not received');
            }
        } catch (error) {
            console.error('Error:', error);
            setError('An error occurred during login. Please try again.');
        }
    };

    return (
        <div className="login-container">
            <h2>Welcome to t'order Map</h2>
            <p>Please log in to continue</p>
            {error && <p className="error-message">{error}</p>}
            <center>
                <button className="google-login-button" onClick={() => login()}>
                    <img src="/img/web_neutral_rd_na@1x.png" alt="Google logo" />
                    Sign in with Google
                </button>
            </center>
        </div>
    );
}

export default Login;