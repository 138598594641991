import React, { useState, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Login from './components/Login';
import GoogleCallback from './components/GoogleCallback';
import './App.css';

const Main = lazy(() => import('./components/Main'));

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLoginSuccess = (token) => {
    localStorage.setItem('token', token);
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
  };

  return (
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <Router>
          <div className="app">
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path="/login" element={isLoggedIn ? <Navigate to="/map" /> : <Login onLoginSuccess={handleLoginSuccess} />} />
                <Route path="/map" element={isLoggedIn ? <Main onLogout={handleLogout} /> : <Navigate to="/login" />} />
                <Route path="/auth/callback" element={<GoogleCallback onLoginSuccess={handleLoginSuccess} />} />
                <Route path="/" element={<Navigate to={isLoggedIn ? "/map" : "/login"} />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </Suspense>
          </div>
        </Router>
      </GoogleOAuthProvider>
  );
}

export default App;