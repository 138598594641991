import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  body {
    font-family: Pretendard, "Helvetica", "Arial", sans-serif;
    margin: 0;
    padding: 0;
  }

  h1, h2, p {
    margin: 0;
  }

  input {
    border: none;
    outline-color: black;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  button {
    border: none;
  }
`;

export default GlobalStyle;